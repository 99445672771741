<template>
  <v-form v-if="localForm" class="position-relative">
    <div>
      <v-row>
        <v-col :cols="12">
          <v-card-title
            class="text-h5 textPrimary--text font-weight-semibold"
            >{{ $t('vendor.onboarding.businessGeneralInfo') }}</v-card-title
          >
        </v-col>
        <v-col :md="6" :lg="3">
          <cz-form-field :label="$t('vendor.onboarding.businessName')" required>
            <validation-provider
              rules="required"
              :name="$t('vendor.onboarding.businessName')"
              v-slot="{ errors }"
            >
              <cz-input
                v-model="localForm.businessName"
                :placeholder="$t('vendor.onboarding.businessNameHint')"
                :error-messages="errors"
                :readonly="readonly"
              />
            </validation-provider>
          </cz-form-field>
        </v-col>
        <v-col :md="6" :lg="3">
          <cz-form-field :label="$t('vendor.onboarding.businessId')" required>
            <validation-provider
              rules="required|length:9"
              :name="$t('vendor.onboarding.businessId')"
              v-slot="{ errors }"
            >
              <cz-input
                type="number"
                v-model="localForm._id"
                :readonly="readonly || editMode"
                :flat="readonly"
                :outlined="!readonly"
                :error-messages="errors"
                :solo="readonly"
              />
            </validation-provider> </cz-form-field
        ></v-col>

        <v-col :md="12" :lg="3">
          <cz-form-field :label="$t('vendor.onboarding.businessType')" required>
            <validation-provider
              rules="required"
              :name="$t('vendor.onboarding.businessType')"
              v-slot="{ errors }"
            >
              <cz-autocomplete
                v-model="localForm.businessType"
                :items="businessTypes"
                :error-messages="errors"
                :append-icon="readonly && null"
                :readonly="readonly"
              />
            </validation-provider>
          </cz-form-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12">
          <v-card-title>{{
            $t('vendor.onboarding.businessContactInfo')
          }}</v-card-title>
        </v-col>
        <v-col :md="6" :lg="3" :sm="12">
          <cz-form-field
            :label="$t('vendor.onboarding.contactPersonName')"
            required
          >
            <validation-provider
              rules="required"
              :name="$t('vendor.onboarding.contactPersonName')"
              v-slot="{ errors }"
            >
              <cz-input
                v-model="localForm.contactPersonName"
                :error-messages="errors"
                :readonly="readonly"
              />
            </validation-provider>
          </cz-form-field>
        </v-col>
        <v-col :md="6" :lg="3" :sm="12">
          <cz-form-field :label="$t('vendor.onboarding.phone')" required>
            <validation-provider
              rules="required|phoneNumber"
              :name="$t('vendor.onboarding.phone')"
              v-slot="{ errors }"
            >
              <cz-input
                v-model="localForm.contactPhoneNumber"
                :error-messages="errors"
                :type="readonly ? 'text' : 'tel'"
                :readonly="readonly"
              />
            </validation-provider>
          </cz-form-field>
        </v-col>
        <v-col :md="6" :lg="3" :sm="12">
          <cz-form-field
            :label="$t('vendor.onboarding.documentsMail')"
            required
          >
            <validation-provider
              rules="required|email"
              :name="$t('vendor.onboarding.documentsMail')"
              v-slot="{ errors }"
            >
              <cz-input
                v-model="localForm.contactEmail"
                :error-messages="errors"
                :readonly="readonly"
              />
            </validation-provider>
          </cz-form-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12">
          <v-card-title>{{ $t('vendor.onboarding.address') }}</v-card-title>
        </v-col>
        <v-col :md="12" :lg="3" :sm="12">
          <cz-form-field :label="$t('vendor.onboarding.street')" required>
            <validation-provider
              rules="required"
              :name="$t('vendor.onboarding.street')"
              v-slot="{ errors }"
            >
              <cz-input
                v-model="localForm.street"
                :error-messages="errors"
                :readonly="readonly"
              />
            </validation-provider>
          </cz-form-field>
        </v-col>
        <v-col :md="6" :lg="3" :sm="12">
          <cz-form-field :label="$t('vendor.onboarding.city')" required>
            <validation-provider
              rules="required"
              :name="$t('vendor.onboarding.city')"
              v-slot="{ errors }"
            >
              <cz-autocomplete
                v-model="localForm.city"
                :error-messages="errors"
                :items="cities"
                item-text="name"
                item-value="name"
                :readonly="readonly"
              />
            </validation-provider>
          </cz-form-field>
        </v-col>
        <v-col :md="6" :lg="3" :sm="12">
          <cz-form-field :label="$t('vendor.onboarding.postalCode')" required>
            <validation-provider
              rules="required"
              :name="$t('vendor.onboarding.postalCode')"
              v-slot="{ errors }"
            >
              <cz-input
                v-model="localForm.postalCode"
                :error-messages="errors"
                :readonly="readonly"
              />
            </validation-provider>
          </cz-form-field>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import { CzFormField, CzInput, CzAutocomplete } from '@/components';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import cities from '@/data/israeli-cities.json';
import { typesMixin } from '@/shared/mixins';
export default {
  name: 'OnBoardingBusinessDetails',
  components: {
    CzFormField,
    CzInput,
    CzAutocomplete
  },
  mixins: [typesMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.localForm = cloneDeep(this.value);
  },
  data() {
    return {
      cities,
      localForm: null
    };
  },
  watch: {
    localForm: {
      deep: true,
      handler(value) {
        if (!isEqual(value, this.value)) {
          this.$emit('input', value);
        }
      }
    }
  }
};
</script>

<style></style>
